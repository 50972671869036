import React from 'react';
import { Route } from 'react-router'
import ListIcon from '@material-ui/icons/List'
import EmailIcon from '@material-ui/icons/Email'
import FilterListIcon from '@material-ui/icons/FilterList'
import ConnectionsIcon from '@material-ui/icons/SettingsInputComponent'
import AlarmIcon from '@material-ui/icons/Alarm'
import DescriptionIcon from '@material-ui/icons/Description';
import { Admin, Resource } from 'react-admin';
import { InstanceCreate, InstanceList, InstanceEdit } from '../main-modules/connections';
import { AlarmList, AlarmEdit, AlarmCreate } from '../main-modules/alarms';
import { ErrorFilterList, ErrorFilterEdit, ErrorFilterCreate } from '../main-modules/error-filters';
import { ErrorReportList } from '../main-modules/error-report';
import { AlarmRecipientListEdit, AlarmRecipientListCreate, AlarmRecipientListList } from '../main-modules/recipient-lists';
import { RunDataShow, EntityDataShow, TaskShow, EntityShow, RunList, RunShow, LogShow } from '../main-modules/runs'
import { ObjectShow, ObjectList, ObjectEdit } from '../main-modules/objects';
import { ConnectionCreated } from './custom-pages';
import LoginRedirect from './login-redirect';


class AdminWrapper extends React.Component {
    render() {
        return (
            <Admin i18nProvider={this.props.i18nProvider}
                loginPage={LoginRedirect}
                authProvider={this.props.authProvider}
                dataProvider={this.props.dataProvider}
                customRoutes={[
                    <Route
                        key="connection-created"
                        path="/connection-created"
                        component={ConnectionCreated}
                    />
                ]}>
                {permissions => permissions.group.groupValue <= 100 ? [
                    <Resource name="runs" />,
                    <Resource name="integrationinstances" list={InstanceList} edit={InstanceEdit} create={InstanceCreate} />,
                    <Resource name="alarms" />,
                    <Resource name="alarmrecipientlists" />,
                    <Resource name="alarmtemplates" />,
                    <Resource name="modules" />,
                    <Resource name="integrations" />,
                    <Resource name="integrationconfigurations" />,
                    <Resource name="entities" />,
                    <Resource name="entitydatas" />,
                    <Resource name="rundatas" />,
                    <Resource name="tasks" />,
                    <Resource name="moduleconfigurations" />,
                    <Resource name="entrypoints" />,
                    <Resource name="logs" />,
                    <Resource name="companies" />,
                    <Resource name="endpoints" />,
                    <Resource name="companies/subdivisions" />,
                    <Resource name="settingsvalidation/validateintegration" />
                ] : [
                    <Resource icon={() => (<ListIcon color="primary" />)} name="runs" show={RunShow} list={RunList} />,
                    <Resource icon={() => (<DescriptionIcon color="primary" />)} name="objects" show={ObjectShow} list={ObjectList} edit={ObjectEdit} />,
                    <Resource icon={() => (<ConnectionsIcon color="primary" />)} options={{ label: 'Connections' }} name="integrationinstances" create={InstanceCreate} edit={InstanceEdit} list={InstanceList} />,
                    <Resource icon={() => (<FilterListIcon color="primary" />)} options={{ label: 'Error filters' }} name="errorfilters" list={ErrorFilterList} edit={ErrorFilterEdit} create={ErrorFilterCreate} />,
                    <Resource icon={() => (<FilterListIcon color="primary" />)} options={{ label: 'Error report' }} name="errorreport" list={ErrorReportList} />,
                    <Resource icon={() => (<AlarmIcon color="primary" />)} options={{ label: 'Alarms' }} name="alarms" list={AlarmList} edit={AlarmEdit} create={AlarmCreate} />,
                    <Resource icon={() => (<EmailIcon color="primary" />)} options={{ label: 'Recipient lists' }} name="alarmrecipientlists" list={AlarmRecipientListList} edit={AlarmRecipientListEdit} create={AlarmRecipientListCreate} />,
                    <Resource name="alarmtemplates" />,
                    <Resource name="modules" />,
                    <Resource name="integrations" />,
                    <Resource name="integrationconfigurations" />,
                    <Resource name="companies/subdivisions" />,
                    <Resource name="entities" show={EntityShow} />,
                    <Resource name="entitydatas" show={EntityDataShow} />,
                    <Resource name="rundatas" show={RunDataShow} />,
                    <Resource name="tasks" show={TaskShow} />,
                    <Resource name="moduleconfigurations" />,
                    <Resource name="entreintegrationlogs" />,
                    <Resource name="entreintegrationsettings" />,
                    <Resource name="entreidmappings" />,
                    <Resource name="entrypoints" />,
                    <Resource name="previouslyhandledobjects" />,
                    <Resource name="logs" show={LogShow} />,
                    <Resource name="companies" />,
                    <Resource name="endpoints" />,
                    <Resource name="scheduling" />,
                    <Resource name="settingsvalidation/validateintegration" />
                ]}
            </Admin>
        )
    };
}

export default AdminWrapper;
